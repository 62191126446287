/* You can add global styles to this file, and also import other style files */
/**  Basic Page CSS **/
body {
  font-weight: 200;
  background-color: #222;
}


footer {
  font-size: 0.8em;
}

.bg-primary,
.btn-primary {
  background-color: #222 !important;
  border: inherit;
}

.main-loader{
  text-align: center
}

.search-appointments{
  margin:0
}

.Formlink, .Formlink:active, .Formlink:visited, .Formlink:hover{
  display: inline-block;
  padding: 10px;
  color:white;
  text-decoration: none;
  border:solid 1px rgb(75, 75, 75);
}
.Formlink:first-child{
  border-radius:5px 0 0 5px;
}
.Formlink:last-child{
  border-radius:0 5px 5px 0 ;
}
.Formlink.active {
  background: rgb(75, 75, 75)
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 9pt;
  }
  .container{
    padding:0 2px !important
  }
}

/***********************table fixed head***********/
.tableFixHead          { overflow-y: auto; height: 800px; }
.tableFixHead thead th { position: sticky; top: 0; }

th     { background:#eee; }

/**** column sticky **/


.firstColumnSticky th:first-child, td:first-child
{
  position:sticky;
  left:0px;
  z-index: 10;
 
}
.firstColumnSticky th:first-child{
  z-index: 15;
}
.firstColumnSticky td:first-child
 {
  background-color:#eee;
  font-weight: 600;
 }