.cardIcon {
  width: 20px;
  float: left;
  margin-right: 10px;
}
.cardIcon img{
  width: 100%;
}

.card-pic-results{
  width: 150px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  float: left;
}
.ListaPosts .card {
  padding: 0;
  border-left:0;
  border-right:0;
  border-top:0
}

.ListaPosts .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.25rem;
}