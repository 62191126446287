nav a, nav a:hover, nav a:active{
  color: white;
}

.list-none{
  list-style: none;
}

.svg-white{
  fill:#ddd
}