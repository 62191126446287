.lista-videos{
  padding: 20px 0;
  text-align: center;
}

.item_herramientas_videos{
  padding: 5px;
  margin-bottom: 35px;
  font-weight: bold;
}

.item_herramientas_videos label{
  height: 40px;
}